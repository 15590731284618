/* design */
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { Affix, Button, Dropdown, Layout } from 'antd';
import { FormOutlined, FacebookOutlined, InstagramOutlined, YoutubeOutlined, LinkedinOutlined } from '@ant-design/icons';

/* components */
import Admin from './views/Admin';
import Dropd from "./views/Dropdown";
import Homepage from './views/Homepage';
import Players from './views/Players';
import Rating from './views/Rating';
import Results from './views/Results';
import Schedules from './views/Schedules';
import SignUp from './views/SignUp';
import Sidebar from "./views/Sidebar";
import SemEquipa from "./views/SemEquipa";
import Points from "./views/Points";

/* images */
import league_logo from "./assets/img/logo.png";
import aeisep_logo from "./assets/img/logoae.png";
import pporto from './assets/img/pporto.png';
import moche from './assets/img/moche.png';
import ipdj from './assets/img/ipdj.png';

/* css stylesheet */
import './App.css';
import Inscricoes from "./views/Inscricoes";

/* this could/should be changed over to
database so that Desportivo can activate or
deactivate signup without Informatico's help */
const signupAvailable = false;
const { Header, Footer, Content } = Layout;
const currentDate = new Date().getFullYear();

const SignupButton = () => {
    /* should not appear on signup page */
    if (window.location.pathname === "/inscricao") {
        return null;
    }
    if (!signupAvailable) {
        return null;
    }
    return (
        <Affix id="signup-affix">
            <Button id="signup-btn" icon={<FormOutlined />} size="large" href="/inscricao">Inscreve-te!</Button>
        </Affix>
    );
}

function App() {

    if (window.location.pathname === '/admin') {
        return <Admin />;
    }
    return (
        <Layout className="layout">
            <Header id="header" className="header-common">
                <c className="header-element" id="drop-btn">
                    {/* <Dropd /> */}
                </c>
                <a className="header-element" href="/">
                    <img id="league-logo" src={league_logo} alt="Liga ISEP Logo" />
                </a>
                <a className="header-element" href="/">
                    <span id="league-name">Liga ISEP</span>
                </a>
                <SignupButton />
            </Header>
            <Content className="layout-content">
                <b>
                    {/* <Sidebar /> */}
                </b>
                <div className="display">
                    <Router>
                        <Switch>
                            <Route exact path="/jogadores">
                                <Players />
                            </Route>
                            <Route exact path="/inscricoes">
                                <Inscricoes />
                            </Route>
                            <Route exact path="/dados-individuais">
                                <SemEquipa />
                            </Route>
                            <Route exact path="/horarios">
                                <Schedules />
                            </Route>
                            <Route exact path="/resultados">
                                <Results />
                            </Route>
                            <Route exact path="/classificacao">
                                <Rating />
                            </Route>
                            <Route exact path="/pontos">
                                <Points />
                            </Route>
                            {
                                signupAvailable &&
                                <Route exact path="/inscricao">
                                    <SignUp />
                                </Route>
                            }
                            <Route exact path="/">
                                <Homepage />
                            </Route>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            </Content>
            <Footer className="rc-footer">
                <section className="rc-footer-container">
                    <div className="rc-footer-columns">
                        <div className="rc-footer-column">
                            <h3 className="footer-title">
                                Contactos
                            </h3>
                            <hr className="divider" />
                            <p>
                                <span><b>Morada:</b> Rua de S. Tomé S/Nº 4200-485 Porto<br /></span>
                                <span><b>Email:</b> <a id="email-link" href="mailto:desportivo@aeisep.pt" target="_blank" rel="noreferrer">desportivo@aeisep.pt</a><br /></span>
                                <span><b>Telefone:</b> 964 783 603</span>
                            </p>
                        </div>
                        <div className="rc-footer-column">
                            <div className="footer-logo">
                                <a href="https://aeisep.pt/" target="_blank" rel="noreferrer">
                                    <img className="aeisep-logo" src={aeisep_logo} alt="Logo aeISEP" />
                                </a>
                            </div>
                            <div className="centered-row">
                                <div className="social-network-icon">
                                    <a href="https://www.facebook.com/aeISEP" target="_blank" rel="noreferrer">
                                        <Button id="fb-btn" icon={<FacebookOutlined />} />
                                    </a>
                                </div>
                                <div className="social-network-icon">
                                    <a href="https://www.instagram.com/aeisep/?hl=pt" target="_blank" rel="noreferrer">
                                        <Button id="ig-btn" icon={<InstagramOutlined />} />
                                    </a>
                                </div>
                                <div className="social-network-icon">
                                    <a href="https://www.youtube.com/channel/UCWu2CVe0N7AflWkaVILf2sg" target="_blank" rel="noreferrer">
                                        <Button id="yt-btn" icon={<YoutubeOutlined />} />
                                    </a>
                                </div>
                                <div className="social-network-icon">
                                    <a href="https://www.linkedin.com/company/associa%C3%A7%C3%A3o-de-estudantes-do-isep/mycompany/" target="_blank" rel="noreferrer">
                                        <Button id="li-btn" icon={<LinkedinOutlined />} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="rc-footer-column">
                            <h3 className="footer-title">
                                Patrocínios
                            </h3>
                            <hr className="divider" />
                            <div className="patrocinios">
                                <div className="sponsor">
                                    <img id="sponsor-pporto" className="sponsor-image" src={pporto} alt="Patrocínio IPDJ" />
                                </div>
                                <div className="sponsor">
                                    <img id="sponsor-moche" className="sponsor-image" src={moche} alt="Patrocínio MOCHE" />
                                </div>
                                <div className="sponsor">
                                    <img id="sponsor-ipdj" className="sponsor-image" src={ipdj} alt="Patrocínio IPDJ" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Footer>
            <div className="copyright-footer">
                <span>© {currentDate} aeISEP Dep. Informático. Todos os direitos reservados.</span>
            </div>
        </Layout>
    );
}

export default App;