import React from 'react';
import '../assets/css/Homepage.css';
import '../assets/css/Sidebar.css';
import 'antd/dist/antd.css';
import cartaz from "../assets/img/cartaz.png";
import placeholder from "../assets/img/placeholder.jpg";
import Regulation from '../assets/docs/Regulamento_2024.pdf';

function Homepage() {
    return (
        <div className="homepage-container">
            <a href={Regulation} 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{
                    display: "inline-block",
                    padding: "10px 20px",
                    backgroundColor: "#891b19",
                    color: "#fff",
                    border: "1px solid #fff",
                    textDecoration: "none",
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    transition: "background 0.3s",
                    textAlign: "center"
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = "#64110F"}
                onMouseOut={(e) => e.target.style.backgroundColor = "#891b19"}
            >
                REGULAMENTO
            </a>

            <iframe className="forms"
                src="https://docs.google.com/forms/d/e/1FAIpQLSdHTDL2rD3XRLlFhyiT75phUZrlbaJ9JU5JfIlstyboEix_RQ/viewform?embedded=true"
                width="100%" height="auto" frameBorder="0" marginHeight="0" marginWidth="0">
                A carregar…
            </iframe>
        </div>
    );
}

export default Homepage;
